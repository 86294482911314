// Action Types.
import { FETCH_GAMEPLAYS_SUCCESS, FETCH_GAMEPLAYS_ERROR } from '../actions/actionTypes'

// Initial State.
const initialState = {
  statistics: {},
}

const gameplayReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GAMEPLAYS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { payload } = action
      return { ...state, statistics: payload }
    case FETCH_GAMEPLAYS_ERROR:
      // eslint-disable-next-line no-case-declarations
      return { ...state, statistics: {} }
    default:
      return state
  }
}

export default gameplayReducer
