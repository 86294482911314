// Action Types.
import { FETCH_USERS_SUCCESS, FETCH_USERS_ERROR, FETCH_USERS_REQUEST } from '../actions/actionTypes'

// Initial State.
const initialState = {
  users: [],
  loading: false,
  error: false,
}

const usersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_SUCCESS:
      return { ...state, users: action.payload, loading: false, error: false }
    case FETCH_USERS_REQUEST:
      return { ...state, loading: true }
    case FETCH_USERS_ERROR:
      return { ...state, loading: false, error: true }
    default:
      return state
  }
}

export default usersListReducer
