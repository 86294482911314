import { useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import isObject from 'lodash/isObject'
import groupBy from 'lodash/groupBy'

import {
  getAnalyticsReports,
  getPlayerBalances,
  getPlayerChangelog,
  getReports,
  getGameplays,
  getPlayersOnline,
  getPlayers,
  getPlayer,
  updatePlayer,
  getPlayerGames,
  postBonusTransaction,
} from '../axios'

export { default as queryClient } from './client'

export function usePlayerGames(playerId) {
  const info = useQuery(['player_games', playerId], () => getPlayerGames(playerId))

  return info
}

export function usePlayerBalances(playerId) {
  const info = useQuery(['player_balances', playerId], () => getPlayerBalances(playerId))

  return info
}

export function useAddBonusTransactionMutation(onSuccess, onError) {
  const queryClient = useQueryClient()

  const mutationInfo = useMutation(postBonusTransaction, {
    onSuccess(data) {
      queryClient.invalidateQueries(['player_balances'])

      if (onSuccess) onSuccess(data)
    },
    onError(err) {
      console.error('ERROR ADDING BONUS TRANSACTION', err)

      if (onError) onError(err)
    },
  })

  return mutationInfo
}

export function useReports(type) {
  const info = useQuery([`reports_${type}`], getReports(type))

  return info
}

export function useGameplays() {
  const info = useQuery(['gameplays'], getGameplays)

  return info
}

export function usePlayersOnline() {
  const info = useQuery(['players_online'], getPlayersOnline)

  return info
}

export function usePlayers() {
  const info = useQuery(['players'], getPlayers)

  return info
}

export function usePlayer(playerId) {
  const info = useQuery(['player', playerId], () => getPlayer(playerId))

  return info
}

export function useUpdatePlayerMutation(onSuccess, onError) {
  const queryClient = useQueryClient()

  const { data: players = [] } = usePlayers()

  const mutationInfo = useMutation(
    ([playerId, data]) => {
      const playerData = players.find(({ id }) => playerId === id)

      return updatePlayer(playerId, { ...playerData, ...data })
    },
    {
      onSuccess(data, [playerId]) {
        queryClient.invalidateQueries(['players'])
        queryClient.invalidateQueries(['player', playerId])
        queryClient.invalidateQueries(['players_changelog', playerId])

        if (onSuccess) onSuccess(data)
      },
      onError(err) {
        console.error('ERROR UPDATING PLAYER', err)
        if (onError) onError(err)
      },
    }
  )

  return mutationInfo
}

export function usePlayerChangelog(playerId, enabled = true) {
  const info = useQuery(['players_changelog', playerId], () => getPlayerChangelog(playerId), {
    enabled,
  })

  return info
}

// Rules example: { exit: { rule: "=", value: 31 } }
export function useAnalytics(filters, useQueryOptions) {
  const queryData = useMemo(
    () =>
      Object.entries(filters).map(([field, data]) => {
        if (!isObject(data)) return { field_name: field, symbol: '=', value: data }

        return { field_name: field, symbol: data.rule, value: data.value }
      }),
    [filters]
  )

  const info = useQuery([filters], () => getAnalyticsReports(queryData), useQueryOptions)

  return info
}

export function useDwellAverageAnalytics(playerId = null) {
  const info = useAnalytics(
    Object.assign({}, ...[{ name: 'dwell' }, playerId && { player_id: playerId }].filter(Boolean)),
    {
      select: (data) =>
        Number(
          (
            data.reduce((acc, { timestamp: val }) => acc + val, 0) /
            (1000 * Math.max(1, data.length))
          ).toFixed(0)
        ),
    }
  )

  return info
}

export function useLoginAnalyticsByOs(playerId = null) {
  const info = useAnalytics(
    Object.assign({}, ...[{ name: 'login' }, playerId && { player_id: playerId }].filter(Boolean)),
    { select: (data) => groupBy(data, 'device') }
  )

  return info
}
