import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { QueryClientProvider } from 'react-query'
import App from './App'

import { icons } from './assets/icons'

import store from './store'
import { queryClient } from './queries'

React.icons = icons

const persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
