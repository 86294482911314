import { combineReducers } from 'redux'
import sessionReducer from './sessionReducer'
import usersListReducer from './usersListReducers'
import usersOnlineReducer from './usersOnlineReducers'
import playerDetailsReducer from './playerDetailsReducer'
import currencyReducer from './currencyReducer'
import providerReducer from './providerReducer'
import systemGeneratorReducer from './systemGeneratorReducer'
import gameplayReducer from './gameplayReducer'

const initialState = {
  sidebarShow: 'responsive',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

export default combineReducers({
  ui: changeState,
  session: sessionReducer,
  usersList: usersListReducer,
  usersOnline: usersOnlineReducer,
  playerDetails: playerDetailsReducer,
  currency: currencyReducer,
  provider: providerReducer,
  systemGenerator: systemGeneratorReducer,
  gameplays: gameplayReducer,
})
