// Action Types.
import { FETCH_LOGIN_ERROR, FETCH_LOGIN_REQUEST, FETCH_LOGIN_SUCCESS } from '../actions/actionTypes'

const userToken = localStorage.getItem('token')
const storedUser = localStorage.getItem('user')

// Initial State.
const initialState = {
  token: userToken || null,
  user: storedUser ? JSON.parse(storedUser) : null,
  loading: false,
  error: false,
}

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOGIN_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { payload } = action
      // eslint-disable-next-line no-case-declarations
      const { admin: user, token } = payload
      return {
        ...state,
        user,
        token,
        error: false,
      }

    case FETCH_LOGIN_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      }

    case FETCH_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      }

    default:
      return state
  }
}

export default sessionReducer
